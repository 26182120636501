import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import ps2_img from '../assets/images/planetside_div.avif';
import d2_img from '../assets/images/destiny2_div.avif';
import wf_img from '../assets/images/warfame_div.avif';
import sto_img from '../assets/images/star_trek_online_div.avif';
import ffxiv_img from '../assets/images/final_fantasy_xiv_div.avif';
import config from '../../config';

const IndexPage = () => (
  <Layout pageHeader={true} menuBar={true}>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li> 
            <a href={config.socialLinks.find(link => link.name === 'Discord').url} className="button primary">
              Join us
            </a>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="info">
        <a href="#info" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="info" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Our foundation built from its humble beginnings
          </h2>
          <p>
            Voodoo Shipping Company began with a group of US Navy friends starting a Minecraft server in 2010.
            <br />
            As the community grew, we have expanded to multiple game divisions created and ran by our members.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon solid fa-ship major style1">
              <span className="label">Navy</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-users major style2">
              <span className="label">Friends</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-cubes major style3">
              <span className="label">Built</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={ps2_img} alt="" />
        </div>
        <div className="content">
          <h2>
            PlanetSide 2
          </h2>
          <p>
            <i>Our Emerald NC outfit, Voodoo Company (VCO), is an infantry-focused outfit that specializes in rapid redeployment, map movement, and solid platoon/squad cohesion. Being one of the oldest, largest, and most active PS2 outfits, we have plenty of recurring events throughout the week ranging from full-platoon operations to combat air/armor nights.</i>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={d2_img} alt="" />
        </div>
        <div className="content">
          <h2>
            Destiny 2
          </h2>
          <p>
            <i>Our D2 clan, Voodoo Shipping Company (VCO), is a PvX community that hosts organized Raid/Strike runs each week while filling the time with casual Crucible/Gambit runs. We strive to create a welcoming atmosphere for everyone from all skills levels. Regardless of whether you're a casual or a hardcore player, if you ever need a helping hand, all you need to do is ask.</i>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={wf_img} alt="" />
        </div>
        <div className="content">
          <h2>
            Warframe
          </h2>
          <p>
            <i>Our Warframe clan is a guild of space, pirate, wizard, robot ninjas, in space, seeking to keep the system in balance, have the freshest of fashion, and a really good time in an ever-evolving universe. We welcome all Tenno, new and old, sleeping and awoken. Join in on one of our Ninja Nights and see what it's all about. See ya around the system, Tenno!</i>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={sto_img} alt="" />
        </div>
        <div className="content">
          <h2>
            Star Trek Online
          </h2>
          <p>
            <i>Our STO Fleet, Voodoo Fleet, is a casual group that endeavors to have fun in the Star Trek universe. We mostly do PvE TFOs (Task Force Operations), Star Trek TV show/movie themed nights, and story missions when they are released. Setting our phasers to fun, and boldly going where no gamer has gone before.</i>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={ffxiv_img} alt="" />
        </div>
        <div className="content">
          <h2>
            Final Fantasy XIV
          </h2>
          <p>
            <i>Our FFXIV Free Company is a growing casual group, consisting of all experience levels and jobs across the game. Whether it be role playing, grinding levels, raiding, treasure hunting, designing rooms in the FC, crafting, or just hanging out we encourage all mature peoples to join us. We are located on the data center Dynamis, server Hallicarnassus. See ya in Eorzea Warrior of Light!</i>
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Our culture and identity built from its foundation</h2>
          <p>
            We have flourished with the principle that we are a group of friends that enjoy being with one another.
            <br />
            All of our activities revolve around serving our members and guests with a welcoming and exciting community.
          </p>
        </header>
        <ul className="features">
          <li className="icon fa-comment">
            <h3>Socialize</h3>
            <p>
              We stay engaged through our various social media platforms and in-game communities managed by a dedicated staff.
            </p>
          </li>
          <li className="icon solid fa-gamepad">
            <h3>Play</h3>
            <p>
              Aside from our official divisions, there are plenty of others games that we play together or sometimes try to build something more in.
            </p>
          </li>
          <li className="icon solid fa-music">
            <h3>Express</h3>
            <p>
              Whether it be an intriguing conversation, content worth sharing, or karaoke night, you should be able to be yourself that you want to be.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Respect</h3>
            <p>
              Everybody love everybody to promote a better well-being, collective, and sense of belonging.
            </p>
          </li>
          <li className="icon solid fa-hands-helping">
            <h3>Give</h3>
            <p>
              It is important to give back to others through our semi-annual charity fundraisers and external community building activities.
            </p>
          </li>
          <li className="icon solid fa-seedling">
            <h3>Grow</h3>
            <p>
              To foster a thriving community, we are always aspiring to find new ways to build more on our foundation.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Voodoo Shipping Company</h2>
          <p>
            Come join us on our Discord server to begin your next gaming chapter with our community.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href={config.socialLinks.find(link => link.name === 'Discord').url} className="button fit primary">
              Join us
            </a>
          </li>
          <li>
            <Scroll type="id" element="info">
              <a href="#info" className="button fit">
                Learn More
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
